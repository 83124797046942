var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm no-margin",
          attrs: { title: "LBL0000221" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5" },
              [
                _c("c-text", {
                  attrs: {
                    required: true,
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "작업명",
                    name: "workSummary",
                  },
                  model: {
                    value: _vm.workPermit.workSummary,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "workSummary", $$v)
                    },
                    expression: "workPermit.workSummary",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-vendor", {
                  attrs: {
                    disabled: !_vm.isWriting || _vm.isVendor,
                    editable: _vm.editable,
                    label: "LBL0000224",
                    name: "vendorCd",
                  },
                  on: { dataChange: _vm.vendorChange },
                  model: {
                    value: _vm.workPermit.vendorCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "vendorCd", $$v)
                    },
                    expression: "workPermit.vendorCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5" },
              [
                _c("c-location", {
                  attrs: {
                    isMultiple: "",
                    isMapShowing: "",
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    data: _vm.workPermit,
                    etcInput: "locationEtc",
                    label: "LBL0000225",
                    name: "workLocation",
                  },
                  on: { dataChange: _vm.setLocationInfo },
                  model: {
                    value: _vm.workPermit.workLocation,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "workLocation", $$v)
                    },
                    expression: "workPermit.workLocation",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-process", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "공정",
                    name: "processCd",
                  },
                  model: {
                    value: _vm.workPermit.processCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "processCd", $$v)
                    },
                    expression: "workPermit.processCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5" },
              [
                _c("c-equip", {
                  attrs: {
                    isMultiple: "",
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    plantCd: _vm.workPermit.plantCd,
                    processCd: _vm.workPermit.processCd,
                    label: "LBL0000227",
                    name: "equipmentCd",
                  },
                  on: { dataChange: _vm.equipChange },
                  model: {
                    value: _vm.workPermit.equipmentCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "equipmentCd", $$v)
                    },
                    expression: "workPermit.equipmentCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    rows: 1,
                    label: "LBL0000230",
                    name: "specialRequirements",
                  },
                  model: {
                    value: _vm.workPermit.specialRequirements,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "specialRequirements", $$v)
                    },
                    expression: "workPermit.specialRequirements",
                  },
                }),
              ],
              1
            ),
            _vm.isVendor
              ? _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
                  [
                    _c("c-field", {
                      attrs: {
                        required: true,
                        disabled: !_vm.isWriting,
                        editable: _vm.editable,
                        data: _vm.workPermit,
                        plantCd: _vm.workPermit.plantCd,
                        deptValue: "workManagerDeptCd",
                        type: "dept_user",
                        userType: "user",
                        label: "허가서담당자(금호티앤엘)",
                        beforeText: "",
                        name: "workManagerId",
                      },
                      model: {
                        value: _vm.workPermit.workManagerId,
                        callback: function ($$v) {
                          _vm.$set(_vm.workPermit, "workManagerId", $$v)
                        },
                        expression: "workPermit.workManagerId",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isVendor
              ? _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
                  [
                    _c("c-text", {
                      attrs: {
                        disabled: true,
                        editable: _vm.editable,
                        label: _vm.requestLabel,
                        name: "request",
                      },
                      model: {
                        value: _vm.request,
                        callback: function ($$v) {
                          _vm.request = $$v
                        },
                        expression: "request",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-checkbox", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    codeGroupCd: "SOP_PROTECTIVE_GEAR_CD",
                    isObject: true,
                    valueText: "sopProtectiveGearName",
                    valueKey: "sopProtectiveGearCd",
                    label: "LBL0000231",
                    name: "protectiveGears",
                  },
                  model: {
                    value: _vm.workPermit.protectiveGears,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "protectiveGears", $$v)
                    },
                    expression: "workPermit.protectiveGears",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-checkbox", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    codeGroupCd: "SOP_HEAVY_EQUIPMENT_CD",
                    isObject: true,
                    valueText: "sopHeavyEquipmentName",
                    valueKey: "sopHeavyEquipmentCd",
                    label: "중장비",
                    name: "heavyEquipments",
                  },
                  model: {
                    value: _vm.workPermit.heavyEquipments,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "heavyEquipments", $$v)
                    },
                    expression: "workPermit.heavyEquipments",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3" },
          [
            _c("c-table", {
              attrs: {
                title: "LBL0000232",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.workPermit.checkResults,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                editable: _vm.editable && _vm.isWriting,
                contentsField: _vm.contentsField,
              },
              on: { "table-data-change": _vm.tableDataChange },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-9 col-lg-9" }, [
          _c("div", { staticClass: "row" }, [
            !_vm.isVendor
              ? _c(
                  "div",
                  { staticClass: "col-xs-4 col-sm-4 col-md-4 col-lg-4" },
                  [
                    _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm no-margin",
                        attrs: { title: "신청정보" },
                      },
                      [
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c("c-field", {
                                attrs: {
                                  required: !_vm.isVendor,
                                  disabled: !_vm.isWriting,
                                  editable: _vm.editable,
                                  data: _vm.workPermit,
                                  deptValue: "issuedDeptCd",
                                  type: "dept_user",
                                  label: "담당자 검토",
                                  name: "issuedUserId",
                                },
                                model: {
                                  value: _vm.workPermit.issuedUserId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workPermit,
                                      "issuedUserId",
                                      $$v
                                    )
                                  },
                                  expression: "workPermit.issuedUserId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c("c-field", {
                                attrs: {
                                  required: !_vm.isVendor,
                                  disabled: !_vm.isWriting,
                                  editable: _vm.editable,
                                  data: _vm.workPermit,
                                  deptValue: "approvalDeptCd",
                                  type: "dept_user",
                                  label: "팀장 승인",
                                  name: "approvalUserId",
                                },
                                model: {
                                  value: _vm.workPermit.approvalUserId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workPermit,
                                      "approvalUserId",
                                      $$v
                                    )
                                  },
                                  expression: "workPermit.approvalUserId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-textarea", {
                                attrs: {
                                  disabled: !_vm.isWriting,
                                  editable: _vm.editable,
                                  rows: 1,
                                  label: "작업부서(팀)장 전달사항",
                                  name: "workDeptHeadDeliveryMessage",
                                },
                                model: {
                                  value:
                                    _vm.workPermit.workDeptHeadDeliveryMessage,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workPermit,
                                      "workDeptHeadDeliveryMessage",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "workPermit.workDeptHeadDeliveryMessage",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isVendor
              ? _c(
                  "div",
                  { staticClass: "col-xs-4 col-sm-4 col-md-4 col-lg-4" },
                  [
                    _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm no-margin",
                        attrs: { title: "승인정보" },
                      },
                      [
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c("c-field", {
                                attrs: {
                                  required: !_vm.isVendor,
                                  disabled: !_vm.isWriting,
                                  editable: _vm.editable,
                                  data: _vm.workPermit,
                                  deptValue: "relationCooperation1DeptCd",
                                  type: "dept_user",
                                  label: "담당자 검토",
                                  name: "relationCooperation1UserId",
                                },
                                model: {
                                  value:
                                    _vm.workPermit.relationCooperation1UserId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workPermit,
                                      "relationCooperation1UserId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "workPermit.relationCooperation1UserId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c("c-field", {
                                attrs: {
                                  required: !_vm.isVendor,
                                  disabled: !_vm.isWriting,
                                  editable: _vm.editable,
                                  data: _vm.workPermit,
                                  deptValue: "relationCooperation2DeptCd",
                                  type: "dept_user",
                                  label: "팀장 승인",
                                  name: "relationCooperation2UserId",
                                },
                                model: {
                                  value:
                                    _vm.workPermit.relationCooperation2UserId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workPermit,
                                      "relationCooperation2UserId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "workPermit.relationCooperation2UserId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-textarea", {
                                attrs: {
                                  disabled: !_vm.isWriting,
                                  editable: _vm.editable,
                                  rows: 1,
                                  label: "승인부서(팀)장 전달사항",
                                  name: "approvalDeptHeadDeliveryMessage",
                                },
                                model: {
                                  value:
                                    _vm.workPermit
                                      .approvalDeptHeadDeliveryMessage,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workPermit,
                                      "approvalDeptHeadDeliveryMessage",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "workPermit.approvalDeptHeadDeliveryMessage",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isVendor
              ? _c(
                  "div",
                  { staticClass: "col-xs-4 col-sm-4 col-md-4 col-lg-4" },
                  [
                    _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm no-margin",
                        attrs: { title: "LBL0000247" },
                      },
                      [
                        _c(
                          "template",
                          { slot: "card-button" },
                          [
                            _c(
                              "q-btn-group",
                              { attrs: { outline: "" } },
                              [
                                _vm.isWriting
                                  ? _c("c-btn", {
                                      attrs: {
                                        label: "신청/승인 정보 불러오기",
                                        color: "teal-custom",
                                        icon: "download",
                                      },
                                      on: { btnClicked: _vm.getUserSetting },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c("c-field", {
                                attrs: {
                                  disabled: !_vm.isWriting,
                                  editable: _vm.editable,
                                  data: _vm.workPermit,
                                  deptValue: "maintenanceDeptCheckDeptCd",
                                  type: "dept_user",
                                  label: "신청부서 확인",
                                  name: "maintenanceDeptCheckUserId",
                                },
                                model: {
                                  value:
                                    _vm.workPermit.maintenanceDeptCheckUserId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workPermit,
                                      "maintenanceDeptCheckUserId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "workPermit.maintenanceDeptCheckUserId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c("c-field", {
                                attrs: {
                                  disabled: !_vm.isWriting,
                                  editable: _vm.editable,
                                  data: _vm.workPermit,
                                  deptValue: "maintenanceDeptEntryDeptCd",
                                  type: "dept_user",
                                  label: "승인부서 확인",
                                  name: "maintenanceDeptEntryUserId",
                                },
                                model: {
                                  value:
                                    _vm.workPermit.maintenanceDeptEntryUserId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workPermit,
                                      "maintenanceDeptEntryUserId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "workPermit.maintenanceDeptEntryUserId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-field", {
                                attrs: {
                                  disabled: !_vm.isWriting,
                                  editable: _vm.editable,
                                  data: _vm.workPermit,
                                  deptValue: "maintenanceDeptConfirmDeptCd",
                                  type: "dept_user",
                                  label: "확인부서",
                                  name: "maintenanceDeptConfirmUserId",
                                },
                                model: {
                                  value:
                                    _vm.workPermit.maintenanceDeptConfirmUserId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workPermit,
                                      "maintenanceDeptConfirmUserId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "workPermit.maintenanceDeptConfirmUserId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
              [
                _c(
                  "c-table",
                  {
                    ref: "gasTable",
                    attrs: {
                      title: "LBL0000236",
                      columns: _vm.grid2.columns,
                      gridHeight: _vm.grid2.height,
                      data: _vm.workPermit.gases,
                      filtering: false,
                      columnSetting: false,
                      usePaging: false,
                      editable: _vm.editable && _vm.isWriting,
                      selection: "multiple",
                      rowKey: "sopGasCheckId",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable && _vm.isWriting
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "LBL0000253",
                                    icon: "add",
                                    showLoading: false,
                                  },
                                  on: { btnClicked: _vm.addGas },
                                })
                              : _vm._e(),
                            _vm.editable && _vm.isWriting
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "LBL0000275",
                                    icon: "remove",
                                    showLoading: false,
                                  },
                                  on: { btnClicked: _vm.removeGas },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }